import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { Button } from 'react-bootstrap';

const GOOGLE_MAPS_KEY = process.env.REACT_GOOGLE_MAPS_API_KEY || window.REACT_GOOGLE_MAPS_API_KEY;

const POLAND_CENTER = { lat: 52.069, lng: 19.480 }; // Near Piątek

function MapSearch({ onSave, onCancel, initialBounds }) {
  const mapRef = useRef(null);
  const drawingManagerRef = useRef(null);
  const rectangleRef = useRef(null);
  const [selectedBounds, setSelectedBounds] = useState(null);

  useEffect(() => {
    if (!initialBounds || !mapRef.current || !window.google) return;

    const { maps } = window.google;
    const { northEast, southWest } = initialBounds;

    const ne = new maps.LatLng(northEast.lat, northEast.lng);
    const sw = new maps.LatLng(southWest.lat, southWest.lng);
    const bounds = new maps.LatLngBounds(sw, ne);

    const rectangle = new maps.Rectangle({
      bounds,
      editable: true,
      draggable: true,
      fillColor: '#009a00',
      fillOpacity: 0.2,
      strokeWeight: 2,
      map: mapRef.current,
    });

    rectangleRef.current = rectangle;

    setSelectedBounds(initialBounds);
  }, [initialBounds]);

  const handleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    if (initialBounds) {
      const { northEast, southWest } = initialBounds;
      const ne = new maps.LatLng(northEast.lat, northEast.lng);
      const sw = new maps.LatLng(southWest.lat, southWest.lng);
      const bounds = new maps.LatLngBounds(sw, ne);

      const rectangle = new maps.Rectangle({
        bounds,
        editable: true,
        draggable: true,
        fillColor: '#009a00',
        fillOpacity: 0.2,
        strokeWeight: 2,
        map: mapRef.current,
      });

      rectangleRef.current = rectangle;
      setSelectedBounds(initialBounds);
    }

    const drawingManager = new maps.drawing.DrawingManager({
      drawingMode: maps.drawing.OverlayType.RECTANGLE,
      drawingControl: false,
      rectangleOptions: {
        fillColor: '#009a00',
        fillOpacity: 0.2,
        strokeWeight: 2,
        clickable: false,
        editable: true,
      },
    });

    drawingManager.setMap(map);
    drawingManagerRef.current = drawingManager;

    maps.event.addListener(drawingManager, 'rectanglecomplete', (rectangle) => {
      if (rectangleRef.current) {
        rectangleRef.current.setMap(null);
      }

      rectangleRef.current = rectangle;
      const bounds = rectangle.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      setSelectedBounds({
        northEast: { lat: ne.lat(), lng: ne.lng() },
        southWest: { lat: sw.lat(), lng: sw.lng() },
      });

      drawingManager.setDrawingMode(null);
    });
  };

  const handleReset = () => {
    if (rectangleRef.current) {
      rectangleRef.current.setMap(null);
      rectangleRef.current = null;
    }
    setSelectedBounds(null);
    // Re-enable drawing mode
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setDrawingMode('rectangle');
    }
  };

  return (
    <div>
      <div style={{ height: '90vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY, libraries: ['drawing'] }}
          defaultCenter={POLAND_CENTER}
          defaultZoom={7}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
          options={{ minZoom: 7, maxZoom: 12 }}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '1rem' }}>
        <Button
          bsStyle="success"
          disabled={!selectedBounds}
          onClick={() => onSave(selectedBounds)}
        >
          Zapisz obszar wyszukiwań
          <i className="fa fa-map-marker pull-right" />
        </Button>
        <Button
          bsStyle="default"
          onClick={handleReset}
        >
          Resetuj obszar
        </Button>
        <Button
          bsStyle="default"
          onClick={() => onCancel(selectedBounds)}
        >
          Wróć
        </Button>
      </div>
    </div>
  );
}

MapSearch.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialBounds: PropTypes.shape({
    northEast: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
    southWest: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
  }),
};

export default MapSearch;
